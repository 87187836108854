export const rules = {
  new_build: {
    required: true,
    message: 'New Build field is required',
    trigger: ['change', 'blur']
  },
  tenure: {
    required: true,
    message: 'Tenure field is required',
    trigger: ['change', 'blur']
  },
  sale_price: {
    required: true,
    message: 'Sale Price field is required',
    trigger: ['change', 'blur']
  },
  act_both_ends: {
    required: true,
    message: 'Act on both ends field is required',
    trigger: ['change', 'blur']
  },
  act_both_case: {
    required: true,
    message: 'Act on both ends case field is required',
    trigger: ['change', 'blur']
  },
  'solicitor.company': {
    required: true,
    message: 'Company field is required',
    trigger: ['change', 'blur']
  },
  'solicitor.email': {
    required: true,
    message: 'Email field is required',
    trigger: ['change', 'blur']
  },
  'solicitor.name': {
    required: true,
    message: 'Name field is required',
    trigger: ['change', 'blur']
  },
  'solicitor.phone_number': {
    required: true,
    message: 'Phone Number field is required',
    trigger: ['change', 'blur']
  },
  property: {
    address_type: {
      required: true,
      message: 'Address Type field is required',
      trigger: ['change', 'blur']
    }
  }
}
