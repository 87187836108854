import { defineStore } from 'pinia'
import { ref } from 'vue'
import { appAxios } from '@/utils/appAxios'
const API_PREFIX = process.env.VUE_APP_API_PREFIX

export const useSolicitorStore = defineStore('SolicitorStore', () => {
    const solicitorCompanies = ref([])

    const getSolicitors = async (filters) => {
        await appAxios.get(`${API_PREFIX}/companies`, filters).then((response) => {
            solicitorCompanies.value = response
        })
    }
    return {
        solicitorCompanies,
        getSolicitors
    }
})
