<template>
  <el-row :gutter="20" class="mt-4">
    <el-col :xs="24">
      <el-form-item
        :prop="props.prefix + props.modelPropPath + 'line'"
        :label="props.labelName"
        :rules="{
          required: false,
          message: 'Line field is required',
          trigger: ['change', 'blur'],
        }"
      >
        <el-select
          v-model="props.addressModel.line"
          filterable
          remote
          reserve-keyword
          class="w-full"
          placeholder="Search address"
          :remote-method="search"
          :loading="addressStore.loading"
          :disabled="lineReadonly"
          @change="selectAddress"
          size="large"
        >
          <el-option
            v-for="item in addressStore.addresses"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
    </el-col>
  </el-row>
  <el-row class="mt-6 mb-3" :gutter="10">
    <el-col :span="12">
      <el-form-item
        label="Flat No."
        :prop="props.prefix + props.modelPropPath + 'flat_number'"
      >
        <el-input
          v-model="props.addressModel.flat_number"
          placeholder="Flat Number"
          @input="clearUprn()"
        />
      </el-form-item>
    </el-col>
    <el-col :span="12">
      <el-form-item
        label="Building Name"
        :prop="props.prefix + props.modelPropPath + 'building_name'"
        :rules="{
          required: props.addressModel.building_number === '',
          message: 'Building Name field is required',
          trigger: ['change', 'blur'],
        }"
      >
        <el-input
          v-model="props.addressModel.building_name"
          placeholder="Building Name"
          @input="clearUprn()"
        />
      </el-form-item>
    </el-col>
  </el-row>
  <el-row class="mt-6 mb-3" :gutter="20">
    <el-col :span="12">
      <el-form-item
        label="Building No."
        :prop="props.prefix + props.modelPropPath + 'building_number'"
        :rules="{
          required: props.addressModel.building_name === '',
          message: 'Building Number field is required',
          trigger: ['change', 'blur'],
        }"
      >
        <el-input
          v-model="props.addressModel.building_number"
          placeholder="Building Number"
          @input="clearUprn()"
        />
      </el-form-item>
    </el-col>
    <el-col :span="12">
      <el-form-item
        label="Street Name"
        :prop="props.prefix + props.modelPropPath + 'street_name'"
      >
        <el-input
          v-model="props.addressModel.street_name"
          placeholder="Street Name"
          @input="clearUprn()"
        />
      </el-form-item>
    </el-col>
  </el-row>
  <el-row class="mt-6 mb-3" :gutter="20">
    <el-col :span="12">
      <el-form-item
        label="Town/City"
        :prop="props.prefix + props.modelPropPath + 'city'"
        :rules="{
          required: true,
          message: 'Town/City field is required',
          trigger: ['change', 'blur'],
        }"
      >
        <el-input
          v-model="props.addressModel.city"
          placeholder="City"
          @input="clearUprn()"
        />
      </el-form-item>
    </el-col>
    <el-col :span="12">
      <el-form-item
        label="Country"
        :prop="props.prefix + props.modelPropPath + 'country'"
      >
        <el-input
          v-model="props.addressModel.country"
          placeholder="Country"
          @input="clearUprn()"
        />
      </el-form-item>
    </el-col>
  </el-row>
  <el-row class="mt-5 mb-3" :gutter="20">
    <el-col :span="12">
      <el-form-item
        label="Postcode"
        :prop="props.prefix + props.modelPropPath + 'postal_code'"
        :rules="{
          required: true,
          message: 'Postcode is not valid',
          trigger: ['change', 'blur'],
          validator: () =>
            addressStore.checkPostcode(props.addressModel.postal_code),
        }"
      >
        <el-input
          v-model="props.addressModel.postal_code"
          placeholder="Postcode"
          @input="clearUprn()"
        />
      </el-form-item>
    </el-col>
    <el-col v-if="props.isUprn" :span="12">
      <el-form-item
        label="UPRN"
        :prop="props.prefix + props.modelPropPath + 'uprn_number'"
        :rules="{
          required: props.uprnRequired,
          message: 'UPRN field is required',
          trigger: ['change', 'blur'],
        }"
      >
        <el-input
          v-model="props.addressModel.uprn_number"
          placeholder="UPRN"
          :readonly="true"
        />
      </el-form-item>
    </el-col>
  </el-row>
</template>

<script setup>
import { defineProps } from "vue";
import { useAddressStore } from "@/store/AddressStore";

const addressStore = useAddressStore();

let props = defineProps({
  prefix: {
    type: String,
    default: "",
  },
  addressModel: {
    type: Object,
  },
  isUprn: {
    type: Boolean,
    default: false,
  },
  readonly: {
    type: Boolean,
    default: false,
  },
  lineReadonly: {
    type: Boolean,
    default: false,
  },
  lineRequired: {
    type: Boolean,
    default: false,
  },
  uprnRequired: {
    type: Boolean,
    default: false,
  },
  labelName: {
    type: String,
    default: "Search address",
  },
  modelPropPath: {
    type: String,
    default: null,
  },
});

const selectAddress = async (suggestion) => {
  const address = addressStore.addresses.filter(
    (addressItem) => addressItem.value === suggestion
  )[0];
  if (address) {
    await addressStore.getAddressesUdprn(address.udprn).then((res) => {
      props.addressModel.postal_code = res.postcode;
      props.addressModel.building_name = `${res.organisation_name} ${res.building_name}`;
      props.addressModel.building_number = res.building_number;
      props.addressModel.flat_number = res.sub_building_name;
      props.addressModel.street_name = res.thoroughfare;
      props.addressModel.city = res.post_town;
      props.addressModel.county = res.postal_county;
      props.addressModel.address_block = `${res.line_1}, ${res.line_2}, ${res.post_town}, ${res.country}, ${res.postcode}`;
      props.addressModel.description = "";
      props.addressModel.latitude = res.latitude;
      props.addressModel.longitude = res.longitude;
      props.addressModel.uprn_number = res.uprn;
      props.addressModel.ward = res.ward;
      props.addressModel.json_data = res;
      props.addressModel.data_type = "auto";
    });
  }
};

const search = (str) => {
  if (str.length > 2) {
    addressStore.getAddressesIdealPostcode(str);
  }
};

function handleChangeAddressBlock() {
  props.addressModel.address_block = `${props.addressModel.flat_number} ${props.addressModel.street_name}, ${props.addressModel.city}, ${props.addressModel.postal_code}`;
}

const clearUprn = () => {
  props.addressModel.uprn_number = "";
  props.addressModel.data_type = "manual";
  handleChangeAddressBlock();
};
</script>