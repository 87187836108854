import { defineStore } from 'pinia'
import { ref, reactive } from 'vue'
import { appAxios } from '@/utils/appAxios'
const API_PREFIX = process.env.VUE_APP_API_PREFIX

export const useEmployeeStore = defineStore('EmployeeStore', () => {
    const loading = ref(false)
    const employees = ref([])
    const responseMeta = reactive({
        current_page: 1,
        per_page: 10,
        total: 0
    })
    const selectedEmployee = reactive({})

    async function getEmployees(filters) {
        loading.value = true
        return await appAxios.get(`${API_PREFIX}/companies/${filters.company_id}/employees`, filters.queries)
            .then((response) => {
                employees.value = response
                loading.value = false
                return response
            })
    }
    async function getEmployeeById(data) {
        loading.value = true
        await appAxios.get(`${API_PREFIX}/companies/${data.company_id}/employees/${data.employee_id}`).then(response => {
            Object.assign(selectedEmployee, response)
        })
        loading.value = false
    }
    return {
        loading,
        employees,
        selectedEmployee,
        responseMeta,
        getEmployeeById,
        getEmployees
    }
})
