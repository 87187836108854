import { defineStore } from 'pinia'
import { ref } from 'vue'
import { appAxios } from '@/utils/appAxios'

export const useAddressStore = defineStore('AddressStore', () => {
  const loading = ref(false)
  const addresses = ref([])
  const addressesHmlr = ref([])

  function setInitial() {
    addresses.value = []
  }

  async function getAddressesIdealPostcode(str) {
    addresses.value = []
    loading.value = true
    await appAxios.post('address-search', { address: str }).then((response) => {
      loading.value = false
      if (response.error) {
        return false
      }
      setAddresses(response.map((item) => ({ value: item.suggestion, udprn: item.udprn, label: item.suggestion })))
    })
  }

  async function getAddressesUdprn(udprn) {
    return await appAxios.post('address-search-udprn', { udprn })
  }

  function getAddresses(postcode) {
    addresses.value = []
    loading.value = true
    appAxios.get('sprift-postcode-search/'.concat(postcode)).then((response) => {
      loading.value = false
      if (response.error !== '') {
        return false
      }
      setAddresses(response)
    })
  }

  function setAddresses(data) {
    addresses.value = data
  }

  function checkPostcode(postcode) {
    let valid = false


    //HNN NHH => eg. L23 0RH
    valid = postcode.match(/^[a-zA-Z]\d\d\s\d[a-zA-Z][a-zA-Z]$/g) !== null
    if (valid) return valid


    //HHN NHH => eg. HF3 1LN
    valid = postcode.match(/^[a-zA-Z][a-zA-Z]\d\s\d[a-zA-Z][a-zA-Z]$/g) !== null
    if (valid) return valid


    //HHNN NHH => eg. RF33 3BR
    valid = postcode.match(/^[a-zA-Z][a-zA-Z]\d\d\s\d[a-zA-Z][a-zA-Z]$/g) !== null
    if (valid) return valid


    return valid
  }

  const checkHmlrAddress = async (data) => {
    const response = await appAxios.post('address-search-hmlr', data)
    return response
  }

  function setAddressesHmlr(data) {
    addressesHmlr.value = data
  }

  return {
    loading,
    addresses,
    setInitial,
    getAddresses,
    getAddressesIdealPostcode,
    getAddressesUdprn,
    checkPostcode,
    addressesHmlr,
    checkHmlrAddress,
    setAddressesHmlr
  }
})
