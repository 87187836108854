<template>
  <Header :isGoBack="true" />
  <el-container class="container">
    <el-form
      ref="memorandumFormRef"
      :model="memorandumForm"
      :rules="rules"
      class="w-full"
      label-position="top"
      :disabled="!caseStore.editPermission"
    >
      <div class="flex w-full justify-start mb-3">
        <span class="text-sm text-avrillo font-semibold"
          >MEMORANDUM OF SALE LOG IN</span
        >
      </div>
      <!-- New Build Start -->
      <div>
        <el-form-item prop="new_build" label="New Build">
          <el-radio-group v-model="memorandumForm.new_build" class="w-full">
            <el-row :gutter="10" class="w-full">
              <el-col :span="12">
                <el-radio size="large" border label="Yes" class="w-full"
                  >Yes</el-radio
                >
              </el-col>
              <el-col :span="12">
                <el-radio size="large" border label="No" class="w-full"
                  >No</el-radio
                >
              </el-col>
            </el-row>
          </el-radio-group>
        </el-form-item>
      </div>
      <!-- New Build End -->

      <!-- Property Address Start -->
      <div class="mt-4" v-if="memorandumForm.new_build === 'Yes'">
        <el-row :gutter="10" class="m-0 mb-2">
          <el-col :span="24">
            <el-form-item label="Address Type" prop="property.address_type">
              <el-radio-group
                v-model="memorandumForm.property.address_type"
                @change="handleAddressTypeChange"
              >
                <el-radio border label="auto"> Auto Address Finder </el-radio>
                <el-radio border label="manual"> HMLR Check </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <IdealPostcode
              v-if="memorandumForm.property.address_type === 'auto'"
              modelPropPath="property."
              labelName="Property Address"
              :addressModel="memorandumForm.property"
            />
            <HmlrCheck
              v-else
              labelName="Property Address"
              :addressModel="memorandumForm.property"
            />
          </el-col>
        </el-row>
      </div>
      <div class="mt-4" v-if="memorandumForm.new_build === 'No'">
        <el-row :gutter="10" class="m-0 mb-2">
          <el-col :span="24">
            <IdealPostcode
              modelPropPath="property."
              labelName="Property Address"
              :addressModel="memorandumForm.property"
              :isUprn="true"
            />
          </el-col>
        </el-row>
      </div>
      <!-- Property Address End -->

      <!-- Tenure Start -->
      <div class="mt-4">
        <el-form-item prop="tenure" label="Tenure">
          <el-radio-group v-model="memorandumForm.tenure" class="w-full">
            <el-row :gutter="10" class="w-full">
              <el-col :span="12">
                <el-radio size="large" border label="Freehold" class="w-full"
                  >Freehold</el-radio
                >
              </el-col>
              <el-col :span="12">
                <el-radio size="large" border label="Leasehold" class="w-full"
                  >Leasehold</el-radio
                >
              </el-col>
            </el-row>
          </el-radio-group>
        </el-form-item>
      </div>
      <!-- Tenure End -->

      <!-- Sale Price Start -->
      <div class="mt-4">
        <el-form-item prop="sale_price" label="Sale Price">
          <el-input
            v-model="memorandumForm.sale_price"
            size="large"
            class="mt-2"
            placeholder="0,000.00"
            @keyup="$event.target.value = formatPrice($event.target.value)"
          >
            <template #prepend>£</template>
          </el-input>
        </el-form-item>
      </div>
      <!-- Sale Price End -->

      <!-- Seller & Buyer Start -->
      <div class="mt-4">
        <el-row :gutter="10" class="w-full">
          <el-col :span="24">
            <label class="block mb-2 text-avrillo">SELLER(S)</label>
            <!-- Client Card Start -->
            <ClientCard
              @handleDeleteClient="memorandumStore.handleDeleteClient"
              v-for="(client, index) in memorandumForm.seller"
              :client="client"
              :key="index"
              :id="index"
            />
            <!-- Client Card End -->
            <router-link :to="{ path: `/new-client/Seller/-1` }">
              <el-button class="bg-white border-0 text-dark">
                Add Seller
                <i class="ph-plus align-middle bg-primary text-white ml-3"></i>
              </el-button>
            </router-link>
          </el-col>
          <el-col :span="24" class="mt-4">
            <label class="block mb-2 text-avrillo">BUYER(S)</label>
            <!-- Client Card Start -->
            <ClientCard
              @handleDeleteClient="memorandumStore.handleDeleteClient"
              v-for="(client, index) in memorandumForm.buyer"
              :client="client"
              :key="index"
              :id="index"
            />
            <!-- Client Card End -->
            <router-link :to="{ path: `/new-client/Buyer/-1` }">
              <el-button class="bg-white border-0 text-dark">
                Add Buyer
                <i class="ph-plus align-middle bg-primary text-white ml-3"></i>
              </el-button>
            </router-link>
          </el-col>
        </el-row>
      </div>
      <!-- Seller & Buyer End -->

      <!-- Buyer's Solicitor Title Start -->
      <div class="mt-4">
        <div class="flex">
          <label class="text-avrillo mb-2 text-md">BUYER'S SOLICITOR</label>
        </div>
      </div>
      <!-- Buyer's Solicitor Title End -->

      <!-- Act on Both Ends -->
      <div class="mt-2">
        <el-form-item prop="act_both_ends" label="Act on Both Ends">
          <el-radio-group v-model="memorandumForm.act_both_ends" class="w-full">
            <el-row :gutter="10" class="w-full">
              <el-col :span="12">
                <el-radio size="large" border label="Yes" class="w-full"
                  >Yes</el-radio
                >
              </el-col>
              <el-col :span="12">
                <el-radio size="large" border label="No" class="w-full"
                  >No</el-radio
                >
              </el-col>
            </el-row>
          </el-radio-group>
        </el-form-item>
      </div>
      <!-- Act on Both Ends End -->

      <!-- Seller’s Solicitor’s Start -->
      <div class="mt-0" v-if="memorandumForm.act_both_ends === 'No'">
        <div>
          <el-row :gutter="10" class="w-full">
            <el-col :span="24">
              <el-form-item
                label="Seller’s Solicitor’s Company Name"
                prop="solicitor.company"
              >
                <el-autocomplete
                  size="large"
                  placeholder="Seller’s Solicitor’s Company Name"
                  class="w-full inline-input"
                  v-model="solicitorForm.company"
                  @select="handleCompanySelect"
                  :fetch-suggestions="searchCompany"
                  :trigger-on-focus="false"
                />
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <IdealPostcode
                labelName="Seller's Solicitor's Company Address"
                :addressModel="solicitorForm.address"
                modelPropPath="solicitor.address."
                :line-required="true"
                :readonly="isCompanySelected"
                :line-readonly="isCompanySelected"
              />
            </el-col>
          </el-row>
          <el-row :gutter="10" class="w-full">
            <el-col :span="24">
              <el-form-item
                label="Seller’s Solicitor’s Email Address"
                prop="solicitor.email"
              >
                <el-autocomplete
                  size="large"
                  placeholder="Seller’s Solicitor’s Email Address"
                  class="w-full inline-input"
                  v-model="solicitorForm.email"
                  @select="handleEmployeeSelect"
                  :fetch-suggestions="searchEmployee"
                  :trigger-on-focus="false"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10" class="w-full">
            <el-col :span="24">
              <el-form-item
                label="Seller’s Solicitor’s Name"
                prop="solicitor.name"
              >
                <el-input
                  v-model="solicitorForm.name"
                  size="large"
                  placeholder="Seller’s Solicitor’s Name"
                  :disabled="isEmployeeSelected"
                  :readonly="isEmployeeSelected"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10" class="w-full">
            <el-col :span="24">
              <el-form-item
                label="Seller’s Solicitor’s Phone Number"
                prop="solicitor.phone_number"
              >
                <el-input
                  v-model="solicitorForm.phone_number"
                  size="large"
                  placeholder="Seller’s Solicitor’s Company Name"
                  :disabled="isEmployeeSelected"
                  :readonly="isEmployeeSelected"
                />
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </div>
      <!-- Seller’s Solicitor’s End -->

      <!-- Case Search Start -->
      <div class="mt-3" v-if="memorandumForm.act_both_ends === 'Yes'">
        <el-form-item prop="act_both_case" label="Case Search">
          <el-select
            v-model="memorandumForm.act_both_case"
            filterable
            remote
            reserve-keyword
            placeholder="Case Search"
            class="w-full"
            :remote-method="caseStore.searchCase"
          >
            <el-option
              v-for="item in caseStore.conveyancingCases"
              :key="item.id"
              :label="`${item.reference_code} / ${item?.clients[0]?.title} ${item?.clients[0]?.name} ${item?.clients[0]?.surname}`"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
      </div>
      <!-- Case Search End -->

      <!-- Button Start -->
      <div class="mt-8">
        <el-button
          class="w-full bg-primary border-0"
          type="primary"
          @click="submitMemorandumForm"
        >
          <span class="text-xs">Save</span>
        </el-button>
      </div>
      <div class="mt-3">
        <el-button class="w-full border-0 bg-white" type="danger" plain>
          <span class="text-xs">Cancel</span>
        </el-button>
      </div>
      <!-- Button End -->
    </el-form>
  </el-container>
</template>

<script setup>
import { ref } from "vue";
import Header from "@/components/Header";
import ClientCard from "@/components/ClientCard/index";
import IdealPostcode from "@/components/IdealPostcode/index";
import HmlrCheck from "@/components/HmlrCheck/index";
import {
  useMemorandumStore,
  initialPropertyData,
} from "@/store/MemorandumStore";
import { useCaseStore } from "@/store/CaseStore";
import { useAddressStore } from "@/store/AddressStore";
import { useSolicitorStore } from "@/store/SolicitorStore";
import { rules } from "@/utils/rules/memorandum";
import { showNotification } from "@/utils/notification";
import { useEmployeeStore } from "@/store/EmployeeStore";

const memorandumStore = useMemorandumStore();
const caseStore = useCaseStore();
const addressStore = useAddressStore();
const solicitorStore = useSolicitorStore();
const employeeStore = useEmployeeStore();

const memorandumForm = memorandumStore.memorandumForm;
const solicitorForm = memorandumStore.memorandumForm.solicitor;

const isCompanySelected = ref(false);
const isEmployeeSelected = ref(false);
const memorandumFormRef = ref(null);

const submitMemorandumForm = async () => {
  if (memorandumForm.buyer.length > 0 && memorandumForm.seller.length > 0) {
    memorandumFormRef.value.validate((valid) => {
      if (valid) {
        memorandumStore.handleSubmitMemorandum();
      }
    });
  } else {
    showNotification(
      "You must add at least one client (As Seller and Buyer)",
      "error"
    );
  }
};
async function searchCompany(value, cb) {
  isCompanySelected.value = false;
  await solicitorStore
    .getSolicitors({ type: "Conveyancing Company", search: value })
    .then(() => {
      cb(
        solicitorStore.solicitorCompanies.map((solicitorItem) => ({
          value: solicitorItem.name,
          solicitor: solicitorItem,
        }))
      );
    });
}

function handleCompanySelect({ solicitor }) {
  if (solicitor) {
    isCompanySelected.value = true;
    solicitorForm.company_id = solicitor.id;
    solicitorForm.email = solicitor.email;
    solicitorForm.phone_number = solicitor.phone_number;
    if (solicitor.address !== null) {
      solicitorForm.address.address_block = solicitor.address.address_block;
      solicitorForm.address.line = solicitor.address.address_block;
      solicitorForm.address.flat_number = solicitor.address.flat_number;
      solicitorForm.address.building_name = solicitor.address.building_name;
      solicitorForm.address.building_number = solicitor.address.building_number;
      solicitorForm.address.city = solicitor.address.city;
      solicitorForm.address.latitude = solicitor.address.latitude;
      solicitorForm.address.longitude = solicitor.address.longitude;
      solicitorForm.address.street_name = solicitor.address.street_name;
      solicitorForm.address.flat_number = solicitor.address.flat_number;
      solicitorForm.address.postal_code = solicitor.address.postal_code;
      solicitorForm.address.uprn_number = solicitor.address.uprn_number;
    }
  }
}

async function searchEmployee(value, cb) {
  /* if was company selected make search */
  if (isCompanySelected.value) {
    isEmployeeSelected.value = false;
    await employeeStore
      .getEmployees({
        company_id: solicitorForm.company_id,
        queries: { search: value },
      })
      .then(() => {
        cb(
          employeeStore.employees.map((employeeItem) => ({
            value: employeeItem.name,
            employee: employeeItem,
          }))
        );
      });
  } else {
    showNotification("You must choose company!", "error");
    return cb([]);
  }
}

function handleEmployeeSelect({ employee }) {
  if (employee) {
    isEmployeeSelected.value = true;
    solicitorForm.employee_id = employee.id;
    solicitorForm.title = employee.title;
    solicitorForm.name = employee.name;
    solicitorForm.surname = employee.surname;
    solicitorForm.email = employee.email;
    solicitorForm.phone_number = employee.phone_number;
  }
}

const handleAddressTypeChange = (value) => {
  addressStore.setInitial();
  Object.assign(memorandumForm.property, {
    ...initialPropertyData,
    address_type: value,
    data_type: value,
  });
};

function formatPrice(param) {
  return param.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
</script>
