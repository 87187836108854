<template>
  <el-form :model="props.addressModel" ref="form">
    <el-row :gutter="20">
      <el-col :xs="24" :md="9">
        <el-form-item
          label="Postcode"
          :prop="prefix + 'postal_code'"
          :rules="{
            required: true,
            message: 'Postcode is not valid',
            trigger: 'blur',
            validator: () =>
              addressStore.checkPostcode(props.addressModel.postal_code),
          }"
        >
          <el-input
            v-model="props.addressModel.postal_code"
            placeholder="Postcode"
          />
        </el-form-item>
      </el-col>
      <el-col :xs="24" :md="10">
        <el-form-item
          label="Building Number / Name"
          :prop="prefix + 'building_number'"
          :rules="{
            required: true,
            message: 'This field is required',
            trigger: 'blur',
          }"
        >
          <el-input
            v-model="props.addressModel.building_number"
            placeholder="Building Number"
          />
        </el-form-item>
      </el-col>
      <el-col :xs="24" :md="5">
        <el-button
          id="hmlr_btn"
          type="primary"
          class="w-full bg-primary border-0 mb-2"
          @click="handleHmlrCheck"
        >
          Find
        </el-button>
      </el-col>
    </el-row>
  </el-form>
  <el-row>
    <el-col :span="24">
      <el-form-item
        :prop="prefix + 'line'"
        label="Select Address"
        :rules="{
          required: true,
          message: 'You must choose Address',
          trigger: 'change',
        }"
      >
        <el-select
          v-loading="loading"
          v-model="props.addressModel.line"
          @change="selectAddress"
          placeholder="Select Address"
          filterable
          style="width: 100%"
        >
          <el-option
            v-for="item in addressStore.addressesHmlr"
            :key="item._id"
            :label="item.label"
            :value="item._id"
          />
        </el-select>
      </el-form-item>
    </el-col>
  </el-row>
</template>

<script setup>
import { ref, defineProps } from "vue";
import { ElMessage } from "element-plus/es";
import { useAddressStore } from "@/store/AddressStore";

let props = defineProps({
  prefix: {
    type: String,
    default: "",
  },
  addressModel: {
    type: Object,
  },
});

const addressStore = useAddressStore();
const form = ref("");
const loading = ref(false);

async function handleHmlrCheck() {
  let formData = {
    postcode: props.addressModel.postal_code,
    building_field: props.addressModel.building_number,
  };
  form.value.validate(async (valid) => {
    if (valid) {
      loading.value = true;
      await addressStore
        .checkHmlrAddress(formData)
        .then(async (res) => {
          let data = await res.map((item) => {
            return {
              _id: uniqueId(),
              label:
                item.Address.SubBuildingName +
                ", " +
                item.Address.BuildingNumber +
                ", " +
                item.Address.StreetName,
              Address: item.Address,
              TitleNumbers: item.TitleNumbers,
            };
          });
          addressStore.setAddressesHmlr(data);
          loading.value = false;
        })
        .catch(() => {
          ElMessage.error("Please try again with building name");
          loading.value = false;
        });
    } else {
      ElMessage.error("Please fill in the required fields");
    }
  });
}

const selectAddress = (value) => {
  let address = addressStore.addressesHmlr.find((item) => item._id === value);
  props.addressModel.address_block = address.label;
  props.addressModel.title_numbers = address.TitleNumbers;
};

const uniqueId = () => {
  const dateString = Date.now().toString(36);
  const randomness = Math.random().toString(36).substr(2);
  return dateString + randomness;
};
</script>
<style scoped>
.el-form-item__label {
  white-space: nowrap;
  color: aqua !important;
}
</style>